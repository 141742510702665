import React, { useEffect, useState, useRef } from 'react';
import { Routes, Route, Link, useNavigate, Navigate, useLocation } from "react-router-dom";
import { useMediaQuery } from 'react-responsive'
import { sk } from './language.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSignInAlt, faShoppingCart, faSearch, faAngleDoubleLeft, faChevronLeft, faAngleDoubleRight, faChevronRight, faBars, faCross, faTimesCircle, faList, faAngleDown, faAnglesUp, faAngleLeft, faGear, faCaretUp, faArrowRight, faAngleRight, faPerson, faUser, faPenToSquare, faClose, faRightFromBracket, faEdit, faArrowRightToBracket, faHome, faPaperPlane, faPhone, faCheck, faXmark, faHeart } from '@fortawesome/free-solid-svg-icons'
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { Box } from '@mui/system';
import { faEnvelope, faMobile, faMobileAlt, faPlus, faMinus } from '@fortawesome/free-solid-svg-icons'
import { Drawer, Slide } from '@mui/material';
//import Slider from "react-slick";
import { Badge } from '@mui/material';
import { Alert, Autocomplete, Button, IconButton, MenuItem, Select, TextField, Paper } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import { InputLabel } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme2-6.css";
import { styled } from '@mui/material/styles';
import { styles } from './styles.js';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { Parallax } from 'react-scroll-parallax';
import { Spin as Hamburger } from 'hamburger-react';



export const Init = () => {
    localStorage.removeItem('settings');
}

export const SaveLanguage = (lang) => {
    localStorage.setItem('language', lang);
}


export const LoadLanguage = () => {
    var lang = localStorage.getItem('language');
    if (lang != null) {
        return lang;
    } else {
        return 0;
    }
}

export function ValidateEmail(mail) {
    // kontrola správnosti e-mailovej adresy
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,10})+$/.test(mail)) {
        return (true)
    }
    return (false)
};


export const MenuTop = (props) => {
    /*
            MENU - horná lišta

            USAGE:
            <MenuTop func={MenuTopPress.bind(this)} />

            const MenuTopPress = (value) => {                
            }

    */
    const [language, setLanguage] = useState(0);

    const isLarge = useMediaQuery({ minWidth: global.device_medium + 1 });
    const isMedium = useMediaQuery({ minWidth: global.device_small + 1, maxWidth: global.device_medium });
    const isSmall = useMediaQuery({ maxWidth: global.device_small });

    const navigate = useNavigate();

    const flag_sk = require('./react/app/flag_sk.png');
    const flag_en = require('./react/app/flag_en.png');

    const menuHeight = 40;
    const menuHeightSmall = 40;

    let { func } = props;
    let lang = sk;

    useEffect(() => {
        var l = LoadLanguage();
        setLanguage(l);

        return () => {
        };

    }, []);

    const Press = (type, value) => {
        func(type, value);
    }

    const ChangeLang = (id) => {
        setLanguage(id);
        SaveLanguage(id);
        navigate('/');
        window.location.reload();
    }

    return (
        <Paper elevation={6} style={{ ...styles.BlockRow, position: 'relative', height: isSmall ? menuHeightSmall : menuHeight, backgroundColor: global.theme_black, alignItems: 'center', borderRadius: 0, zIndex: 100 }}>
            <div style={{ ...styles.BlockRow }}>
                <div style={{ ...styles.BlockRow }}>
                    <div style={{ ...styles.BlockRow, width: isSmall ? '70%' : '50%', paddingLeft: isSmall ? global.padding_small : global.padding }}>
                        <p style={{ ...isSmall ? styles.TextTiny : styles.TextNormal, color: global.theme_medium_gray, fontWeight: 'normal', marginRight: 5 }}>{lang.email.toLowerCase()}:</p>
                        <p style={{ ...isSmall ? styles.TextTiny : styles.TextNormal, color: global.theme_white, fontWeight: 'normal' }}>{global.company.email}</p>
                    </div>
                    <div style={{ ...styles.BlockRow, justifyContent: 'flex-end', width: isSmall ? '30%' : '50%', paddingRight: isSmall ? global.padding_small : global.padding }}>

                    </div>
                </div>
            </div>
        </Paper>
    )

};


export const MenuLogo = (props) => {
    /*
            

            USAGE:
            <MenuLogo func={MenuLogoPress.bind(this)} />

            const MenuLogoPress = (value) => {            
            }

    */
    const [label, setLabel] = React.useState('');
    const [isOpen, setOpen] = useState(false);
    const [isToggle, setToggle] = useState(false);

    const isLarge = useMediaQuery({ minWidth: global.device_medium + 1 });
    const isMedium = useMediaQuery({ minWidth: global.device_small + 1, maxWidth: global.device_medium });
    const isSmall = useMediaQuery({ maxWidth: global.device_small });

    const timer = useRef();
    let { func } = props;
    var lang = props.lang;

    const navigate = useNavigate();
    const location = useLocation();

    const menuHeight = 100;
    const bodyHeightLarge = 500 - menuHeight;

    const menuHeightMedium = 90;
    const bodyHeightMedium = 500 - menuHeight;

    const menuHeightSmall = 80;
    const bodyHeightSmall = 400 - menuHeight;


    useEffect(() => {

        setToggle(false);

        return () => {
        };

    }, [props.index]);

    const Press = (type) => {
        func(type);
    }

    const logo = require('./react/app/logo.png');

    const datadream = require('./react/app/logo.png');

    // BANNER
    const banner_lg = require('./react/app/banner_lg.jpg');
    const banner_md = require('./react/app/banner_md.jpg');
    const banner_sm = require('./react/app/banner_sm.jpg');


    const ChangeMenu = (id) => {
        setOpen(false);
        setToggle(false);

        switch (id) {
            case 0:
                navigate('/');
                //setLabel(lang.home_text);

                break;
            case 1:
                navigate('/services');
                //setLabel(lang.order_text);
                break;
            case 2:
                navigate('/contact');
                //setLabel(lang.contact_text);
                break;
        }
        Press(id);
    }

    const OpenMenu = () => {
        setOpen(true);
        timer.current = setTimeout(() => {
            setToggle(true);
        }, 400);
    }

    const CloseMenu = () => {
        setOpen(false);
        timer.current = setTimeout(() => {
            setToggle(false);
        }, 225);
    }

    return (
        <div style={{ width: '100%', height: props.index > 0 ? isSmall ? menuHeightSmall : menuHeight : 'auto', position: 'relative', justifyContent: 'center', alignItems: 'center', marginBottom: 0, zIndex: 100, backgroundColor: global.theme_lighter }}>

            <img src={isSmall ? banner_sm : isMedium ? banner_md : banner_lg} style={{ minHeight: isSmall ? 500 : isMedium ? 500 : 500, maxWidth: isSmall || '100%' ? '100%' : '100%' }} />

            <div style={{ ...styles.Block, height: isSmall ? menuHeightSmall : menuHeight, position: 'absolute', top: 0, background: isSmall ? global.theme_gradient_small : global.theme_gradient, alignItems: 'center' }}>

                <div style={{ ...styles.BlockRow, alignItems: 'center', height: isSmall ? menuHeightSmall : menuHeight }}>
                    {isSmall ?
                        <div style={{ ...styles.Block, width: 50 }}>
                            <Hamburger toggled={isToggle} toggle={() => OpenMenu()} />
                        </div>
                        : null}

                    {/* LOGO */}
                    < div style={{ ...isSmall ? styles.BlockRight : styles.BlockLeft, width: isSmall ? '100%' : '50%', paddingLeft: isSmall ? 0 : global.padding, height: isSmall ? menuHeightSmall : menuHeight, justifyContent: 'center' }}>
                        <img onClick={() => ChangeMenu(0)} src={logo} height={isSmall ? 40 : 55} style={{ cursor: 'pointer' }} />
                    </div>

                    {isSmall ?
                        <div style={{ ...styles.BlockLeft, width: 40 }} />
                        : null}

                    {/* MENU BUTTONS */}
                    {isSmall == false ?
                        <div style={{ ...styles.BlockRight, flexDirection: 'row', paddingRight: global.padding, alignItems: 'center', height: menuHeight }}>
                            <Button onClick={() => ChangeMenu(0)} style={{ ...styles.Button, backgroundColor: null, width: 140, height: 36, borderRadius: 0, borderBottom: props.index == 0 ? '3px solid ' + global.theme_menu_background_selected : '3px solid #00000000' }}>
                                <p style={{ ...styles.TextNormal, fontWeight: props.index == 0 ? 'bold' : 'normal', color: props.index == 0 ? global.theme_menu_selected : global.theme_light_gray }}>{lang.home.toUpperCase()}</p>
                            </Button>
                            <Button onClick={() => ChangeMenu(1)} style={{ ...styles.Button, backgroundColor: null, width: 140, height: 36, borderRadius: 0, borderBottom: props.index == 1 ? '3px solid ' + global.theme_menu_background_selected : '3px solid #00000000' }}>
                                <p style={{ ...styles.TextNormal, fontWeight: props.index == 1 ? 'bold' : 'normal', color: props.index == 1 ? global.theme_menu_selected : global.theme_light_gray }}>{lang.services.toUpperCase()}</p>
                            </Button>
                            <Button onClick={() => ChangeMenu(2)} style={{ ...styles.Button, backgroundColor: null, width: 140, height: 36, borderRadius: 0, borderBottom: props.index == 2 ? '3px solid ' + global.theme_menu_background_selected : '3px solid #00000000' }}>
                                <p style={{ ...styles.TextNormal, fontWeight: props.index == 2 ? 'bold' : 'normal', color: props.index == 2 ? global.theme_menu_selected : global.theme_light_gray }}>{lang.contact.toUpperCase()}</p>
                            </Button>

                        </div>
                        :
                        // MENU SMALL
                        <Drawer
                            anchor={'left'}
                            open={isOpen}
                            onClose={CloseMenu}
                            transitionDuration={{ enter: 500, exit: 250 }}
                        >
                            <div style={{ ...styles.BlockCenter, backgroundColor: global.theme_gray, width: 200, height: '100%' }}>
                                <div style={{ ...styles.BlockRight }}>
                                    <Hamburger toggled={isToggle} toggle={() => CloseMenu()} />
                                </div>
                                <img src={logo} width={150} style={{ marginTop: 50 }} />
                                <Button onClick={() => ChangeMenu(0)} style={{ ...styles.Button, width: 140, marginTop: 50 }}>
                                    <p style={{ ...styles.TextNormal, color: props.index == 0 ? global.theme_black : global.theme_medium_gray }}>{lang.home.toUpperCase()}</p>
                                </Button>
                                <Button onClick={() => ChangeMenu(1)} style={{ ...styles.Button, width: 140, marginTop: 5 }}>
                                    <p style={{ ...styles.TextNormal, color: props.index == 1 ? global.theme_black : global.theme_medium_gray }}>{lang.services.toUpperCase()}</p>
                                </Button>
                                <Button onClick={() => ChangeMenu(2)} style={{ ...styles.Button, width: 140, marginTop: 5 }}>
                                    <p style={{ ...styles.TextNormal, color: props.index == 2 ? global.theme_black : global.theme_medium_gray }}>{lang.contact.toUpperCase()}</p>
                                </Button>
                            </div>
                        </Drawer>
                    }


                </div>
            </div>

            <div style={{ ...styles.Block, position: 'absolute', top: menuHeight, height: isSmall ? bodyHeightSmall : isMedium ? bodyHeightMedium : bodyHeightLarge, justifyContent: 'center', alignItems: 'center' }}>
                {props.show_banner == true ?
                    <div style={{ ...styles.Block }}>
                        {/*
                        <Parallax translateY={[-20, 20]} scale={[0.6, 1.5]}>
                            <img src={datadream} height={isSmall ? 50 : 80} />
                        </Parallax>
                        */}
                        <Parallax translateY={[-20, 20]} style={{ ...styles.Block }}>                           
                            <div style={{ ...styles.Block, paddingTop: 0 }}>
                                <h1 style={{ ...isSmall ? styles.TextLabel : styles.TextXXLabel, color: global.theme_black, fontWeight: 700 }}>{lang.slogan.toUpperCase()}</h1>
                                <p style={{ ...isSmall ? styles.TextLarge : styles.TextLabel, fontFamily: 'Roboto Condensed', color: global.theme_black, fontWeight: 400, marginTop: 20 }}>{lang.slogan_description}</p>
                            </div>
                        </Parallax>
                    </div>
                    :
                    <div style={{ ...styles.Block }}>
                        <p style={{ ...styles.TextXXLabel, color: global.theme_white, marginTop: 0 }}>{label}</p>
                    </div>

                }
            </div>


        </div >
    )

};


export const Loading = () => {
    /*
            USAGE:
            <Loading />
            */


    const logo = require('./react/app/logo_small.png');

    return (
        <Backdrop sx={{ color: '#000000', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true} onClick={null} style={{ backgroundColor: '#00000000' }}>
            <div style={{ ...styles.Block, width: 80, height: 80, backgroundColor: global.theme_white, borderRadius: 50 }}>
                <img src={logo} style={{ width: 50, height: 50 }}></img>
                <CircularProgress style={{ position: 'absolute', color: global.theme_blue }} size={70} />
            </div>
        </Backdrop>
    );
}



export const BannerImage = (props) => {
    /*
            <BannerImage banner={banner} />
            */
    const isLarge = useMediaQuery({ minWidth: global.device_medium + 1 });
    const isMedium = useMediaQuery({ minWidth: global.device_small + 1, maxWidth: global.device_medium });
    const isSmall = useMediaQuery({ maxWidth: global.device_small });

    const navigate = useNavigate();

    var lang = sk;

    const Press = () => {
        if (props.banner.link != '') {
            navigate(props.banner.link);
        }
    }
    return (
        <div style={{ backgroundColor: global.theme_white, padding: 0, display: 'flex', flexDirection: 'row' }}>
            {isSmall == true ?
                <img onClick={() => Press()} src={global.images + 'banners/' + props.banner.image_small} style={{ width: '100%', height: '100%', minHeight: '100%' }} />
                :
                <img onClick={() => Press()} src={global.images + 'banners/' + props.banner.image} style={{ width: '100%', height: '100%', minHeight: '100%' }} />
            }
        </div>
    )
}


export const BannerSlider = (props) => {
    /*
            <BannerSlider />
            */

    //BANNER: 2560 x 800 px
    //BANNER SMALL: 850 x 500 px

    const isLarge = useMediaQuery({ minWidth: global.device_medium + 1 });
    const isMedium = useMediaQuery({ minWidth: global.device_small + 1, maxWidth: global.device_medium });
    const isSmall = useMediaQuery({ maxWidth: global.device_small });


    const sliderSettings = {
        arrows: false,
        dots: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3500,
        speed: 750,
    }

    const Slide = (props) => {
        const navigate = useNavigate();

        const BannerClick = (link) => {
            // kliknutie na banner
            if (link.trim() != '') {
                navigate(link);
            }
        }
        return (
            <div key={props.item.id} style={{ width: '100%', height: 'auto', position: 'relative' }}>
                {isSmall == true ?
                    <a onClick={() => BannerClick(props.item.link)} style={{ width: '100%', height: 'auto' }}>
                        <img src={global.images + '/banners/' + props.item.image_small} style={{ width: '100%', height: '100%', minHeight: '100%' }} />
                        {props.item.text_position == 0 ?
                            // LEFT ALIGN
                            <div style={{ display: 'flex', flexDirection: 'column', height: '100%', justifyContent: 'center', position: 'absolute', left: 0, top: 0, width: '40%', textAlign: 'left', paddingLeft: global.menu_padding }}>
                                <p style={{ margin: 0, fontSize: global.font_banner_label_small, color: props.item.text_color }}>{props.item.label}</p>
                                <p style={{ margin: 0, marginTop: 10, fontSize: global.font_banner_text_small, color: props.item.text_color, fontWeight: 'normal' }}>{props.item.text}</p>
                            </div>
                            : null}
                        {props.item.text_position == 1 ?
                            // CENTER ALIGN
                            <div style={{ display: 'flex', flexDirection: 'column', height: '100%', justifyContent: 'center', position: 'absolute', left: '25%', top: 0, width: '40%', textAlign: 'center', paddingRight: global.menu_padding }}>
                                <p style={{ margin: 0, fontSize: global.font_banner_label_small, color: props.item.text_color }}>{props.item.label}</p>
                                <p style={{ margin: 0, marginTop: 10, fontSize: global.font_banner_text_small, color: props.item.text_color, fontWeight: 'normal' }}>{props.item.text}</p>
                            </div>
                            : null}
                        {props.item.text_position == 2 ?
                            // RIGHT ALIGN
                            <div style={{ display: 'flex', flexDirection: 'column', height: '100%', justifyContent: 'center', position: 'absolute', right: 0, top: 0, width: '40%', textAlign: 'right', paddingRight: global.menu_padding }}>
                                <p style={{ margin: 0, fontSize: global.font_banner_label_small, color: props.item.text_color }}>{props.item.label}</p>
                                <p style={{ margin: 0, marginTop: 10, fontSize: global.font_banner_text_small, color: props.item.text_color, fontWeight: 'normal' }}>{props.item.text}</p>
                            </div>
                            : null}
                    </a>
                    :
                    <a onClick={() => BannerClick(props.item.link)} style={{ width: '100%', height: 'auto' }}>
                        <img src={global.images + '/banners/' + props.item.image} style={{ width: '100%', height: '100%', minHeight: '100%' }} />
                        {props.item.text_position == 0 ?
                            // LEFT ALIGN
                            <div style={{ display: 'flex', flexDirection: 'column', height: '100%', justifyContent: 'center', position: 'absolute', left: 0, top: 0, width: '40%', textAlign: 'left', paddingLeft: global.menu_padding }}>
                                <p style={{ margin: 0, fontSize: global.font_banner_label, color: props.item.text_color }}>{props.item.label}</p>
                                <p style={{ margin: 0, marginTop: 20, fontSize: global.font_banner_text, color: props.item.text_color, fontWeight: 'normal' }}>{props.item.text}</p>
                            </div>
                            : null}
                        {props.item.text_position == 1 ?
                            // CENTER ALIGN
                            <div style={{ display: 'flex', flexDirection: 'column', height: '100%', justifyContent: 'center', position: 'absolute', left: '25%', top: 0, width: '40%', textAlign: 'center', paddingRight: global.menu_padding }}>
                                <p style={{ margin: 0, fontSize: global.font_banner_label, color: props.item.text_color }}>{props.item.label}</p>
                                <p style={{ margin: 0, marginTop: 20, fontSize: global.font_banner_text, color: props.item.text_color, fontWeight: 'normal' }}>{props.item.text}</p>
                            </div>
                            : null}
                        {props.item.text_position == 2 ?
                            // RIGHT ALIGN
                            <div style={{ display: 'flex', flexDirection: 'column', height: '100%', justifyContent: 'center', position: 'absolute', right: 0, top: 0, width: '40%', textAlign: 'right', paddingRight: global.menu_padding }}>
                                <p style={{ margin: 0, fontSize: global.font_banner_label, color: props.item.text_color }}>{props.item.label}</p>
                                <p style={{ margin: 0, marginTop: 20, fontSize: global.font_banner_text, color: props.item.text_color, fontWeight: 'normal' }}>{props.item.text}</p>
                            </div>
                            : null}
                    </a>
                }
            </div>)
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column', width: '100%', paddingBottom: 40 }}>
            <Slider  {...sliderSettings}>
                {props.banners.map(item => {
                    return (
                        <Slide key={item.id} item={item} />
                    )
                })}
            </Slider>
        </div>
    )
}

export const Footer = (props) => {
    /*
            <Footer language={language} />
            */
    const [year, setYear] = useState(new Date().getFullYear());

    const isLarge = useMediaQuery({ minWidth: global.device_medium + 1 });
    const isMedium = useMediaQuery({ minWidth: global.device_small + 1, maxWidth: global.device_medium });
    const isSmall = useMediaQuery({ maxWidth: global.device_small });

    var lang = sk;

    const navigate = useNavigate();

    const logo = require('./react/app/logo_white.png');
    const iconSize = 30;


    return (
        <div style={{ ...styles.Block, position: 'relative', paddingTop: 30, backgroundColor: global.theme_footer, zIndex: 2 }}>

            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', width: '100%' }}>

                {/* LAVA STRANA */}
                <div style={{ ...styles.BlockRight, justifyContent: 'flex-start' }}>
                    <img src={logo} height={isSmall ? 30 : 50} style={{ marginRight: 20 }} />
                </div>

                {/* PRAVA STRANA */}
                <div style={{ ...styles.BlockLeft, borderLeft: '1px solid ' + global.theme_dark_gray, paddingLeft: 20 }} >
                    <p style={{ ...isSmall ? styles.TextSmall : styles.TextNormal, color: global.theme_white, fontWeight: 'bold' }}>{global.company.name}</p>
                    <p style={{ ...isSmall ? styles.TextTiny : styles.TextSmall, color: global.theme_white, fontWeight: 'normal', marginTop: 10, textAlign: 'left' }}>{global.company.street}</p>
                    <p style={{ ...isSmall ? styles.TextTiny : styles.TextSmall, color: global.theme_white, fontWeight: 'normal', marginTop: 5, textAlign: 'left' }}>{global.company.psc} {global.company.town}</p>
                    <p style={{ ...isSmall ? styles.TextTiny : styles.TextSmall, color: global.theme_white, fontWeight: 'normal', marginTop: 5, textAlign: 'left' }}>{global.company.district}</p>
                    <p style={{ ...isSmall ? styles.TextTiny : styles.TextSmall, color: global.theme_white, fontWeight: 'normal', marginTop: 5, textAlign: 'left' }}>{global.company.state}</p>
                    <p style={{ ...isSmall ? styles.TextTiny : styles.TextSmall, color: global.theme_white, fontWeight: 'normal', marginTop: 20, textAlign: 'left' }}>{lang.mobil.toLowerCase()}: {global.company.mobil1}</p>
                    {/*
                    <p style={{ ...isSmall ? styles.TextTiny : styles.TextSmall, color: global.theme_white, fontWeight: 'normal', marginTop: 5, textAlign: 'left' }}>{lang.mobil.toLowerCase()}2: {global.company.mobil2}</p>
                    */}
                    <p style={{ ...isSmall ? styles.TextTiny : styles.TextSmall, color: global.theme_white, fontWeight: 'normal', marginTop: 20, textAlign: 'left' }}>{lang.email.toLowerCase()}: {global.company.email}</p>
                </div>
            </div>

            {/* BOTTOM */}
            <div style={{ width: '100%', marginTop: 20, backgroundColor: '#111111' }}>
                <div style={{ width: '100%', marginTop: 20, paddingTop: 20, paddingBottom: 50 }}>
                    <a href='https://vgvstudio.sk' style={{ margin: 0, color: global.theme_medium_gray, fontSize: global.font_tiny, textDecoration: 'none' }}>© {lang.copyright} {year} - {global.creator}</a>
                </div>
            </div>
        </div >
    );
}

export const Template = (props) => {
    /*
            USAGE:
            <BonusInfo lang={lang} language={language} func={BonusInfoPress.bind(this)} />

            const BonusInfoPress = (value) => {
                Debug(value)
            }

            */

    const isLarge = useMediaQuery({ minWidth: global.device_medium + 1 });
    const isMedium = useMediaQuery({ minWidth: global.device_small + 1, maxWidth: global.device_medium });
    const isSmall = useMediaQuery({ maxWidth: global.device_small });

    let { func } = props;
    var lang = sk;

    useEffect(() => {

        return () => {
        };

    }, []);


    const Press = (value) => {
        func(value);
    }


    return (
        <div>
        </div >
    );
}


export const GoHome = () => {
    window.scrollTo(0, 0);
}

export const Line = (props) => {
    return (
        <div style={{ width: 100, height: 2, backgroundColor: props.color != undefined ? props.color : global.theme_medium, marginTop: props.margin == undefined ? 10 : props.margin, marginBottom: props.margin == undefined ? 10 : props.margin }} />
    )
}

export const GetSlovom = (value) => {
    // vstup 0.0, 123.50, ....
    // vráti slovom od  0 - 999 000
    // aj centy 
    var numbers_1 = ['nula', 'jeden', 'dva', 'tri', 'štyri', 'päť', 'šesť', 'sedem', 'osem', 'deväť'];
    var numbers_2 = ['nula', 'jeden', 'dve', 'tri', 'štyri', 'päť', 'šesť', 'sedem', 'osem', 'deväť'];
    var numbers_3 = ['desať', 'jedennásť', 'dvanásť', 'trinásť', 'štrnásť', 'pätnásť', 'šestnásť', 'sedemnásť', 'osemnásť', 'devätnásť'];
    var numbers_4 = ['', 'desať', 'dsať', 'dsať', 'dsať', 'desiat', 'desiat', 'desiat', 'desiat', 'desiat'];

    var str_ = value.toString().replaceAll(',', '.');
    var arr = str_.split('.');

    var result = '';
    var result2 = '';
    if (arr.length > 0) {
        var str = arr[0];
        var eur = parseInt(str);

        var n = 0;

        while (n < str.length) {
            var chr = str.charAt(str.length - 1 - n);
            var val = parseInt(chr);
            if (n == 0) {
                // jdnotky 1 - 9
                if (val > 0) {
                    result = numbers_1[val];
                }
            }
            if (n == 1) {
                // desiatky
                if (val == 1) {
                    // 10 - 19
                    var sub_val = parseInt(str.charAt(str.length - n));
                    result = numbers_3[sub_val];
                } else {
                    // 20 - 99
                    if (val > 1) {
                        result = numbers_1[val] + numbers_4[val] + result;
                    }
                }
            }
            if (n == 2) {
                // stovky 100 - 900
                if (val > 0) {
                    result = numbers_2[val] + 'sto' + result;
                }
            }
            // ------------------------------------------------------------
            // 1 000 - 100 000
            // ------------------------------------------------------------
            if (n == 3) {
                // tisíce 1 - 9
                if (val > 0) {
                    result2 = numbers_2[val];
                }
            }
            if (n == 4) {
                // desaťtisíce 10 000 - 90 000
                if (val == 1) {
                    // 10 - 19
                    var sub_val = parseInt(str.charAt(str.length - n));
                    result2 = numbers_3[sub_val];
                } else {
                    // 20 - 99
                    if (val > 1) {
                        result2 = numbers_1[val] + numbers_4[val] + result2;
                    }
                }
            }
            if (n == 5) {
                // stotisíce 100 000 - 900 000
                if (val > 0) {
                    result2 = numbers_2[val] + 'sto' + result2;
                }
            }
            n++;
        }
    }

    if (result2 != '') {
        result2 = result2 + 'tisíc';
    }

    /* **********************************************************************
        CENTY
    ********************************************************************** */
    var centy = '';
    if (arr.length > 1) {
        var str = arr[1];

        if (str.length == 1) {
            str = str + '0';
        }
        var cent = parseInt(str);

        var n = 0;
        while (n < str.length) {
            var chr = str.charAt(str.length - 1 - n);
            var val = parseInt(chr);

            if (n == 0) {
                // jdnotky 1 - 9
                if (val > 0) {
                    centy = numbers_1[val];
                }
            }
            if (n == 1) {
                // desiatky
                if (val == 1) {
                    // 10 - 19
                    var sub_val = parseInt(str.charAt(str.length - n));
                    centy = numbers_3[sub_val];
                } else {
                    // 20 - 99
                    if (val > 1) {
                        centy = numbers_1[val] + numbers_4[val] + centy;
                    }
                }
            }
            n++;
        }
    }
    result = result2 + result;
    if (result == '') {
        result = numbers_1[0];
    }

    var eur_text = 'eur';
    if (eur == 0) {
        eur_text = 'eur';
    }
    if (eur == 1) {
        eur_text = 'euro';
    }
    if (eur > 1 && eur < 5) {
        eur_text = 'eura';
    }
    if (eur > 4) {
        eur_text = 'eur';
    }
    result = result + ' ' + eur_text;

    if (centy != '') {
        var cent_text = 'cent';
        if (cent == 1) {
            cent_text = 'cent';
        }
        if (cent > 1 && cent < 5) {
            cent_text = 'centy';
        }
        if (cent > 4) {
            cent_text = 'centov';
        }
        result = result + ' a ' + centy + ' ' + cent_text;
    }
    return result;
}


export const useScrollDirection = () => {
    const [scrollDirection, setScrollDirection] = React.useState('up');

    const blocking = React.useRef(false);
    const prevScrollY = React.useRef(0);
    const THRESHOLD = 50;

    React.useEffect(() => {
        prevScrollY.current = window.pageYOffset;

        const updateScrollDirection = () => {
            const scrollY = window.pageYOffset;

            if (Math.abs(scrollY - prevScrollY.current) >= THRESHOLD) {
                const newScrollDirection =
                    scrollY > prevScrollY.current ? 'down' : 'up';

                setScrollDirection(newScrollDirection);

                prevScrollY.current = scrollY > 0 ? scrollY : 0;
            }

            blocking.current = false;
        };

        const onScroll = () => {
            if (!blocking.current) {
                blocking.current = true;
                window.requestAnimationFrame(updateScrollDirection);
            }
        };

        window.addEventListener('scroll', onScroll);

        return () => window.removeEventListener('scroll', onScroll);
    }, [scrollDirection]);

    return scrollDirection;
};


export const Subscription = (props) => {
    /*
            USAGE:
            <Subscription lang={lang} language={language} func={SubscriptionPress.bind(this)} />

            const SubscriptionPress = (value) => {
                Debug(value)
            }
            */
    const [isBusy, setBusy] = useState(false);
    const [error, setError] = useState('');
    const [name, setName] = useState('');
    const [nameErr, setNameErr] = useState('');
    const [surname, setSurname] = useState('');
    const [surnameErr, setSurnameErr] = useState('');
    const [mobil, setMobil] = useState('');
    const [mobilErr, setMobilErr] = useState('');
    const [email, setEmail] = useState('');
    const [emailErr, setEmailErr] = useState('');
    const [note, setNote] = useState('');
    const [payment, setPayment] = useState(1);
    const [sended, setSended] = useState(false);
    const [price, setPrice] = useState(0);

    const isLarge = useMediaQuery({ minWidth: global.device_medium + 1 });
    const isMedium = useMediaQuery({ minWidth: global.device_small + 1, maxWidth: global.device_medium });
    const isSmall = useMediaQuery({ maxWidth: global.device_small });

    let { func } = props;
    var lang = sk;

    useEffect(() => {

        setName(props.company.name == null ? '' : props.company.name);
        setSurname(props.company.surname == null ? '' : props.company.surname);
        setEmail(props.company.email == null ? '' : props.company.email);
        setMobil(props.company.mobil == null ? '' : props.company.mobil);
        setPrice(props.subscription_year);
        setPayment(1);

        return () => {
        };

    }, []);


    const db_send = async () => {
        try {
            const response = await fetch(
                global.db_url + 'subscription_send', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    company_id: props.company_id,
                    name: name + ' ' + surname,
                    mobil: mobil,
                    email: email,
                    payment: payment,
                    price: price
                })
            })

            const json = await response.json();
            if (json.ok > 0) {
                if (json.sended == true) {
                    setSended(true);
                } else {
                    setError(lang.send_error)
                }
            } else {
                setError(lang.send_error)
            }
        } catch (error) {
            console.log(error);
        }
    }



    const Press = (typ) => {
        func(typ);
    }


    const Send = () => {
        var error = false;
        setError('');
        setNameErr('');
        setSurnameErr('');
        setMobilErr('');
        setEmailErr('');

        if (name.trim() == '') {
            error = lang.required;
            setNameErr(error);
        }

        if (surname.trim() == '') {
            error = lang.required;
            setSurnameErr(error);
        }

        if (mobil.trim() == '') {
            error = lang.required;
            setMobilErr(error);
        }

        if (email.trim() == '') {
            error = lang.required;
            setEmailErr(error);
        }


        if (error != '') {
            setError(error);
        } else {
            // ODOSLAT SPRAVU
            db_send();
        }
    }

    const PaymentChange = (event) => {
        var value = event.target.value;
        if (value == 0) {
            setPrice(props.subscription_month);
        } else {
            setPrice(props.subscription_year);
        }
        setPayment(value);
    }

    return (
        <div style={{ ...styles.Block }}>
            <div style={{ ...styles.BlockRow, backgroundColor: global.theme_dark, height: 50, justifyContent: 'center', alignItems: 'center' }}>
                <div style={{ width: '100%', paddingLeft: 10 }}>
                    <p style={{ ...styles.TextLarge, color: global.theme_white }}>{lang.subscription}</p>
                </div>
                <div style={{ width: 50 }}>
                    <IconButton onClick={() => Press(false)} style={{ ...styles.BlockRound, width: 30, height: 30, backgroundColor: global.theme_light, alignSelf: 'flex-end' }}>
                        <FontAwesomeIcon style={{ width: 20, height: 20, color: global.theme_white }} icon={faXmark} />
                    </IconButton>
                </div>
            </div>

            {sended == false ?
                <div style={{ ...styles.Block, width: 600, paddingTop: 10, paddingBottom: 10 }}>
                    <div style={{ ...styles.Block, width: '90%' }}>
                        <p style={{ ...styles.TextLarge }}>Požiadavka na obnovu predplatného</p>

                        <div style={{ ...styles.Block, marginTop: 20 }}>
                            <RadioGroup
                                defaultValue={1}
                                style={{ ...styles.BlockRow }}
                                onChange={PaymentChange}
                            >
                                <div style={{ ...styles.BlockRowRaw, width: '50%' }}>
                                    <Radio value={0} />
                                    <div>
                                        <p style={{ ...styles.TextTiny, marginTop: 4 }} >Mesačné predplatné</p>
                                        <p style={{ ...styles.TextXXTiny, marginTop: 0 }} >({props.subscription_month} € / mesiac)</p>
                                    </div>
                                </div>
                                <div style={{ ...styles.BlockRowRaw, width: '50%' }}>
                                    <Radio value={1} />
                                    <div>
                                        <p style={{ ...styles.TextTiny, marginTop: 4 }} >Celoročné predplatné</p>
                                        <p style={{ ...styles.TextXXTiny, marginTop: 0 }} >({props.subscription_year} € / rok)</p>
                                    </div>
                                </div>
                            </RadioGroup>
                        </div>

                        {/* MENO, PRIEZVISKO */}
                        <div style={{ ...styles.BlockRow, marginTop: 20 }}>
                            <div style={{ ...styles.BlockLeft, width: '50%', marginRight: 10 }}>
                                <p style={{ ...styles.TextTiny, color: global.theme_medium_gray, textAlign: 'left', fontWeight: 'bold', marginBottom: 5 }}>{lang.name}</p>
                                <TextField error={nameErr == '' ? false : true} helperText={nameErr} size={'small'} style={{ backgroundColor: global.theme_white, width: '100%', textTransform: 'none' }} autoCapitalize='none' label={''} variant="outlined" value={name} onInput={e => setName(e.target.value)} />
                            </div>
                            <div style={{ ...styles.BlockLeft, width: '50%' }}>
                                <p style={{ ...styles.TextTiny, color: global.theme_medium_gray, textAlign: 'left', fontWeight: 'bold', marginBottom: 5 }}>{lang.surname}</p>
                                <TextField error={surnameErr == '' ? false : true} helperText={surnameErr} size={'small'} style={{ backgroundColor: global.theme_white, width: '100%', textTransform: 'none' }} autoCapitalize='none' label={''} variant="outlined" value={surname} onInput={e => setSurname(e.target.value)} />
                            </div>
                        </div>

                        {/* MOBIL, EMAIL */}
                        <div style={{ ...styles.BlockRow, marginTop: 20 }}>
                            <div style={{ ...styles.BlockLeft, width: '50%', marginRight: 10 }}>
                                <p style={{ ...styles.TextTiny, color: global.theme_medium_gray, textAlign: 'left', fontWeight: 'bold', marginBottom: 5 }}>{lang.mobil}</p>
                                <TextField error={mobilErr == '' ? false : true} helperText={mobilErr} size={'small'} style={{ backgroundColor: global.theme_white, width: '100%', textTransform: 'none' }} autoCapitalize='none' label={''} variant="outlined" value={mobil} onInput={e => setMobil(e.target.value)} />
                            </div>
                            <div style={{ ...styles.BlockLeft, width: '50%' }}>
                                <p style={{ ...styles.TextTiny, color: global.theme_medium_gray, textAlign: 'left', fontWeight: 'bold', marginBottom: 5 }}>{lang.email}</p>
                                <TextField error={emailErr == '' ? false : true} helperText={emailErr} size={'small'} style={{ backgroundColor: global.theme_white, width: '100%', textTransform: 'none' }} autoCapitalize='none' label={''} variant="outlined" value={email} onInput={e => setEmail(e.target.value)} />
                            </div>
                        </div>

                        {/* POZNAMKA */}
                        <div style={{ ...styles.BlockLeft, marginTop: 20 }}>
                            <p style={{ ...styles.TextTiny, color: global.theme_medium_gray, textAlign: 'left', fontWeight: 'bold', marginBottom: 5 }}>poznámka k objednávke</p>
                            <TextField rows={2} multiline={true} size={'small'} style={{ backgroundColor: global.theme_white, width: '100%', textTransform: 'none' }} autoCapitalize='none' label={''} variant="outlined" value={note} onInput={e => setNote(e.target.value)} />
                        </div>

                        <p style={{ ...styles.TextTiny, marginTop: 10 }}>Po obdržaní objednávky na obnovu predplaného Vám zašleme faktúru. Po jej úhrade bude účet aktivovaný.</p>

                    </div>
                </div>
                :
                <div style={{ ...styles.Block, width: 600, paddingTop: 50, paddingBottom: 50 }}>
                    {/* BODY */}
                    <div style={{ ...styles.Block, width: '100%', height: '100%', backgroundColor: global.theme_white }}>
                        <div style={{ ...styles.BlockRound, marginTop: 11 }}>
                            <FontAwesomeIcon style={{ width: 30, height: 30, marginRight: 0, color: global.theme_white }} icon={faCheck} />
                        </div>
                        <p style={{ ...styles.TextSmall, marginLeft: 10, marginTop: 20, marginBottom: 5 }}>Požiadavka bola úspešne odoslaná.</p>

                    </div>

                </div>
            }

            <div style={{ ...styles.Block, width: 600, height: 120 }}>
                <div style={{ height: 60 }}>
                    {isBusy == true ?
                        <CircularProgress color="inherit" size={20} />
                        : error != '' ?
                            <Alert severity="error">
                                {error}
                            </Alert>
                            : null
                    }
                </div>
                <div style={{ ...styles.BlockRowRaw, paddingBottom: 20 }}>
                    {sended == false ?
                        <Button onClick={() => Send()} style={{ ...styles.ButtonGreen, margin: 10, width: 220 }}>{lang.send_request}</Button>
                        : null}
                    <Button onClick={() => Press(false)} style={{ ...styles.ButtonBack, margin: 10 }}>{lang.close}</Button>
                </div>
            </div>
        </div >
    );
}


