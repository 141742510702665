import React, { useEffect, useState } from 'react';

import './App.css';
import { Routes, Route, Link, useParams, Navigate, useNavigate } from "react-router-dom";
import { useMediaQuery } from 'react-responsive'
import { Helmet } from "react-helmet";
import { sk } from './language.js';
import { Main } from './main.js';
import { QRCode } from './qrcode.js';
import { Footer, LoadLanguage, MenuLogo, MenuTop, useScrollDirection } from './items';
import { styles } from './styles.js';
import { Services } from './services.js';
import { Contact } from './contact.js';
import { style } from '@mui/system';
import Fade from '@mui/material/Fade';
import { ParallaxProvider } from 'react-scroll-parallax';

export function Manual() {
    const [language, setLanguage] = useState(0);

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const isLarge = useMediaQuery({ minWidth: global.device_medium + 1 });
    const isMedium = useMediaQuery({ minWidth: global.device_small + 1, maxWidth: global.device_medium });
    const isSmall = useMediaQuery({ maxWidth: global.device_small });

    const logo = require('./react/app/logo_white.png');

    //var lang = sk;
    let params = useParams();

    useEffect(() => {
        console.log(params.name);
        console.log(params.language);
    }, [])

    return (
        <div style={{ ...styles.Block }}>
            <p style={{ ...styles.TextTiny, color: global.theme_white }}>MANUAL</p>
        </div>
    );
}
