import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Routes, Route, Link, useParams, Navigate, useNavigate } from "react-router-dom";
import { Manual } from './manual';

ReactDOM.render(
    <React.StrictMode>
        <BrowserRouter>
            <Helmet>
                <title>{global.title}</title>
                <meta name="description" content="VGVStudio - Komplexné softwarové riešenia pre vás a vašu firmu" />
            </Helmet>

            <Routes>                
                <Route path="*" element={<App />} />
                <Route path="/manual/:name/:language" element={<Manual />} />
            </Routes>
        </BrowserRouter>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
